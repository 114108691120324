<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow menu-dark"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <div class="navbar-header d-flex align-items-center expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row w-100 justify-content-between">
          <li class="nav-item mr-auto">
            <b-link to="/">
              <b-img
                :src="appLogoFull"
                alt="logo"
                style="height: 26px; filter: brightness(0) invert(1)"
              />
            </b-link>
          </li>
          <li class="nav-item nav-toggle d-flex align-items-center">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-md-none text-white"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-md-block collapse-toggle-icon text-white"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
//import { provide, computed, ref } from "@vue/composition-api";
import { provide, computed, ref } from 'vue'
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import { mapGetters } from "vuex";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage, appLogoTxt, appLogoFull } = $themeConfig.app;

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoTxt,
      appLogoFull,
    };
  },
  computed: {
    ...mapGetters(["workspaceSelected", "institution", "institutionsList"]),
    navMenuItems() {
      let navMenuItems = [
        {
          title: "Accueil",
          route: "home",
          icon: "dashboard",
          iconType: "google",
          children: [],
        },
        {
          title: "Interventions",
          icon: "assignment",
          iconType: "google",
          // disable: true,
          children: [
            {
              type: "divider",
              title: "Intervention",
              class: "mt-1",
            },
            {
              title: "Toutes les interventions",
              route: "interventions",
            },
            {
              type: "divider",
              title: "Étape courantes",
              route: "types-devices",
            },
            {
              title: "Liste des étapes",
              route: "commonStep",
            },
            {
              type: "divider",
              title: "Tâche courantes",
              route: "types-devices",
            },
            {
              title: "Liste des tâches",
              route: "commonTask",
            },
          ],
        },
        {
          title: "Entreprises",
          icon: "people_alt",
          iconType: "google",
          children: [
            {
              type: "divider",
              title: "Annuaire",
              class: "mt-1",
            },
            {
              title: "Collaborateurs",
              route: "collaborators",
            },
            {
              title: "Clients",
              route: "customers",
            },
            {
              type: "divider",
              title: "Application",
              class: "mt-1",
            },
            {
              title: "Utilisateurs",
              route: "users",
            },
          ],
        },
        {
          title: "Bassins",
          icon: "pool",
          iconType: "google",
          route: "pools",
        },
        {
          title: "Produits",
          icon: "water_drop",
          iconType: "google",
          route: "products",
        },
        {
          title: "Appareils",
          icon: "cleaning_services",
          iconType: "google",
          route: "device",
        },
        {
          title: "Paramètres",
          icon: "settings",
          iconType: "google",
          children: [
            {
              type: "divider",
              title: "Bassin",
              class: "mt-1",
            },
            {
              title: "Types de bassins",
              route: "types-bassins",
            },
            {
              title: "Types de revêtements",
              route: "types-revêtements",
            },
            {
              title: "Types de traitements",
              route: "types-traitements",
            },
            {
              title: "Méthodes d'accès au bassin",
              route: "type-methode-acces",
            },
            {
              title: "Types d'accès au bassin",
              route: "type-acces",
            },
            {
              title: "Types de contrats de maintenances",
              route: "types-contrats-maintenances",
            },
            {
              type: "divider",
              title: "Intervention",
              class: "mt-1",
            },
            {
              title: "Types d'interventions",
              route: "types-interventions",
            },
            // {
            //   title: "Types de tâches",
            //   route: "types-tâches",
            // },
            {
              type: "divider",
              title: "Appareils",
              route: "types-devices",
            },
            {
              title: "Types d'appareils",
              route: "types-devices",
            },
            {
              title: "Marques d'appareils",
              route: "brand",
            },
            {
              type: "divider",
              title: "Utilisateurs",
              route: "types-devices",
            },
            {
              title: "Types de contrats",
              route: "type-contrats-professionnels",
            },
            {
              title: "Métiers",
              route: "jobs",
            },
          ],
        },
      ];
      if (this.workspaceSelected.modules) {
        let bauxChildrenModuleTitles = [
          "Tableau de bord Gestion",
          "Types de baux",
          "Périodicités",
          "Types de biens",
          "Baux",
        ];
        let facturationChildrenModuleTitles = [
          "Tableau de bord Analytique",
          "Paramètres",
        ];
        let achatChildrenModuleTitles = ["Types de charges"];

        for (let i = 0; i < navMenuItems.length; i++) {
          const item = navMenuItems[i];
          if (
            item.title == "Achat" &&
            !this.workspaceSelected.modules.find((el) => el.label == "Achat")
          ) {
            navMenuItems.splice(i, 1);
            i--;
          } else if (
            item.title == "Facturation" &&
            !this.workspaceSelected.modules.find(
              (el) => el.label == "Facturation"
            )
          ) {
            navMenuItems.splice(i, 1);
            i--;
          } else if (
            item.title == "Accueil" ||
            item.title == "Paramètres" ||
            item.title == "Entreprise" ||
            item.title == "Affaires"
          ) {
            for (let j = 0; j < item.children.length; j++) {
              const child = item.children[j];
              if (
                facturationChildrenModuleTitles.includes(child.title) &&
                !this.workspaceSelected.modules.find(
                  (el) => el.label == "Facturation"
                )
              ) {
                item.children.splice(j, 1);
                j--;
              } else if (
                child.title == "Ma société" &&
                (!this.workspaceSelected.modules.find(
                  (el) => el.label == "Facturation" || el.label == "Baux"
                ) ||
                  this.institutionsList.length > 1)
              ) {
                item.children.splice(j, 1);
                j--;
              } else if (
                child.title == "Mes sociétés" &&
                (!this.workspaceSelected.modules.find(
                  (el) => el.label == "Facturation" || el.label == "Baux"
                ) ||
                  this.institutionsList.length <= 1)
              ) {
                item.children.splice(j, 1);
                j--;
              } else if (
                bauxChildrenModuleTitles.includes(child.title) &&
                !this.workspaceSelected.modules.find((el) => el.label == "Baux")
              ) {
                item.children.splice(j, 1);
                j--;
              } else if (
                achatChildrenModuleTitles.includes(child.title) &&
                !this.workspaceSelected.modules.find(
                  (el) => el.label == "Achat"
                )
              ) {
                item.children.splice(j, 1);
                j--;
              } else if (
                child.title == "Environnements" &&
                !this.userRolesLocal.includes("Admin")
              ) {
                item.children.splice(j, 1);
                j--;
              } else if (
                child.title == "Utilisateurs" &&
                !this.userRolesLocal.includes("Admin")
              ) {
                item.children.splice(j, 1);
                j--;
              }
            }
          }
        }
      }
      return navMenuItems;
    },
    userRolesLocal() {
      let info = JSON.parse(localStorage.getItem("userInfo"));
      return info.roles;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
